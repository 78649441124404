.dropdown_component {
  .dropdown_component__trigger {
    &[aria-expanded='true'] {
      @apply bg-secondary-hover;
    }
    &[aria-expanded='false'] {
      @apply bg-secondary;
    }
  }

  .dropdown_component__trigger--flat {
    @apply p-0;
  }
}