.nav_tabs {
  .nav_tabs__tab {
    &[data-active='true'] {
      & a {
        @apply text-primary !important;
      }

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        @apply rounded-full;
        @apply bg-primary;
        @apply bottom-0;
        @apply w-full;
      }
    }

    &[data-active='false'] {
      &:hover::after {
        content: '';
        position: absolute;
        height: 2px;
        @apply bg-gray-300;
        @apply bottom-0;
        @apply w-full;
      }
    }
  }
}
