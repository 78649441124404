* {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}

body {
  @apply text-default;
  @apply text-base;
  @apply antialiased;
  @apply bg-background-base;
}

h1,
h2,
h3,
h4 {
  &:first-child {
    @apply mt-0;
  }
  &:last-child {
    @apply mb-0;
  }
  @apply text-dark;
}

p {
  @apply leading-normal;
  @apply font-normal;
}

a:hover {
  transition: background 0.3s;
}

em {
  font-style: oblique;
  @apply font-semibold;
}

ul,
ol {
  @apply mb-4;

  &:last-child {
    @apply mb-0;
  }
}

.text-label {
  @apply font-semibold;
  @apply text-sm;
  @apply uppercase;
  @apply text-light;
}

select {
  @apply border-gray-200;

  &:hover {
    @apply border-gray-300;
  }
  &:focus {
    @apply border-primary;
  }
}

/* IOS fix to avoid zoom in */
@media screen and (max-width: 640px) {
  input,
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select,
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
