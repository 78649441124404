/* Optimism */
.validated-field.error > .hidden {
  @apply inline-flex;
  @apply text-danger-text;
  @apply mb-3;
}

.validated-field.error > div {
  @apply mb-1;
}

.validated-field.error input,
.validated-field.error textarea {
  @apply border-danger-text;
}

/* Input */
/* purgecss start ignore */
.form-input {
  &[data-invalid='true'] {
    @apply shadow-outline-error;
    @apply border-danger-text;
  }

  &[data-invalid='true']:focus-within {
    @apply shadow-outline-error;
    @apply border-danger-text;
  }

  & > input:invalid {
    box-shadow: none;
  }
}
/* purgecss end ignore */
