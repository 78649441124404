.sidebar_menu {
  width: 260px;

  .sidebar_submenu_content > .sidebar_menu_item > a {
    @apply pl-12 font-normal;
  }

  .sidebar_menu_item a {
    &.active {
      @apply bg-dark-mode-blue-200;
    }
  }

  .sidebar_menu_item_light a {
    &.active {
      @apply bg-orange-50 text-dark-mode-gray-200 font-bold rounded-md;
    }
  }
}
