.order_line_items_addition_component__container {
  &[data-expanded='true'] {
    @apply fixed;
    @apply bg-background-surface;
    @apply inset-0;
    @apply p-4;
  }
  &[data-expanded='false'] {
    @apply relative;
    @apply bg-transparent;
  }

  .order_line_items_addition_component__footer {
    @apply sticky;
    @apply bottom-0;
    @apply bg-none;
    @apply p-4;
    @apply w-full;
    @apply bg-background-surface;
    @apply items-center;
  }

  .order_line_items_addition_component__overlay {
    @apply relative;
    @apply mt-2;
    @apply shadow-none;
    @apply border-none;
    @apply rounded-none;
    @apply bg-none;
  }

  .order_line_items_addition_component__overlay__container {
    position: relative;
    padding-bottom: 24px;
  }
}

@screen sm {
  .order_line_items_addition_component__container {
    &[data-expanded='true'] {
      @apply relative;
      @apply bg-transparent;
      @apply p-0;
    }
    &[data-expanded='false'] {
      @apply relative;
      @apply bg-transparent;
    }

    .order_line_items_addition_component__overlay__container {
      @apply absolute inset-x-0 top-12;
      padding-bottom: 0px;
    }

    .order_line_items_addition_component__footer {
      @apply relative;
      @apply bg-background-surface;
      @apply p-4;
      @apply w-full;
      @apply items-center;
    }

    .order_line_items_addition_component__overlay {
      @apply relative flex flex-col;
      @apply mt-2;
      @apply shadow;
      @apply border;
      @apply border-gray-100;
      @apply rounded;
      @apply bg-background-surface;
    }
  }
}
