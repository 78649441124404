form.spreedly-theme-ollie {
  .spreedly-field {
    @apply flex;
    @apply flex-col;
    @apply mb-4;
    @apply w-full;

    label {
      @apply font-semibold text-sm uppercase text-light;
      @apply mb-1;
      @apply flex;
      @apply flex;
      @apply flex-row;
    }

    select,
    input {
      @apply border-gray-200;
      @apply transition-all;
      @apply duration-300;
      @apply bg-white;
      @apply border;
      @apply rounded;
      @apply py-2;
      @apply bg-white;
      @apply border;
      @apply rounded;
      @apply py-2;
      @apply leading-6;
    }
    select {
      @apply text-base;
      @apply pr-10;
      @apply pl-3;
      @apply text-lg;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNOTguOSwxODQuN2wxLjgsMi4xbDEzNiwxNTYuNWM0LjYsNS4zLDExLjUsOC42LDE5LjIsOC42YzcuNywwLDE0LjYtMy40LDE5LjItOC42TDQxMSwxODcuMWwyLjMtMi42ICBjMS43LTIuNSwyLjctNS41LDIuNy04LjdjMC04LjctNy40LTE1LjgtMTYuNi0xNS44djBIMTEyLjZ2MGMtOS4yLDAtMTYuNiw3LjEtMTYuNiwxNS44Qzk2LDE3OS4xLDk3LjEsMTgyLjIsOTguOSwxODQuN3oiLz48L3N2Zz4=) !important;
      background-size: 15px;
      background-repeat: no-repeat !important;
      background-position-x: 98% !important;
      background-position-y: 50% !important;
      appearance: none !important;
      &::-ms-expand {
        display: none;
      }
    }
    input {
      @apply appearance-none;
      @apply px-3;
      @apply text-lg;
    }
  }
  .spreedly-field-submit {
    button {
      @apply transition-opacity;
      @apply duration-200;
      @apply ease-in-out;
      @apply flex;
      @apply items-center;
      @apply appearance-none;
      @apply justify-center;
      @apply shadow;
      @apply font-medium;
      @apply leading-6;
      @apply cursor-pointer;
      @apply font-semibold;
      @apply rounded-md;
      @apply py-2;
      @apply px-4;
      @apply text-base;
      @apply text-primary;
      @apply border;
      @apply bg-white;
      @apply border-transparent;
      @apply mt-6;
      &:focus {
        outline: none;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      }
      &:hover {
        @apply text-primary-hover;
        opacity: 75%;
      }
      &:active {
        opacity: 100%;
      }
      &:disabled {
        opacity: 50%;
        cursor: not-allowed;
      }
    }
  }
}
