.action_group_component__container {
  &--wrap {
    & > .action_group_component__item {
      @apply p-1;
    }
  }

  &--collapse {
    & > .action_group_component__item {
      @apply p-1;
    }

    .action_group_component__menu_item button {
      padding: 0 !important;
    }
    .action_group_component__menu_item a {
      justify-content: start;
    }
  }
}