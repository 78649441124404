@define-mixin table {
  .table-component__container {

    &--inline {
      @apply border rounded;
    }

    &::before {
      @apply absolute left-0 top-0 bottom-0 w-7 duration-300 transition-shadow;
      z-index: 1;
      content: '';
      pointer-events: none;
    }

    &:after {
      @apply absolute right-0 top-0 bottom-0 w-7 duration-300 transition-shadow;
      z-index: 1;
      content: '';
      pointer-events: none;
    }

    .table-component__scroll {
      table {
        @apply table w-full shadow-none;
      }

      .table-component__header__cell,
      .table-component__cell {
        @apply px-2 py-3 flex-none;

        &:first-child:not(.related-row) {
          @apply pl-4;
        }

        &:last-child {
          @apply pr-4;
        }
      }

      .table-component__header {
        @apply table-header-group;

        .table-component__row {
          @apply table-row bg-gray-75;
          border-collapse: separate;

          &:not(:first-child) {
            @apply hidden;
          }

          .table-component__header__cell {
            @apply font-semibold;

            &.table-component__cell--fix {
              &-left {
                position: sticky !important;
                @apply z-10;
                @apply bg-gray-75 pl-2;

                &-last:after {
                  @apply absolute top-0 right-0 w-7 duration-300 transition-shadow;
                  bottom: -1px;
                  content: '';
                  pointer-events: none;
                  transform: translateX(100%);
                }
              }
            }
          }
        }
      }

      .table-component__body {
        @apply flex-none;

        .table-component__row {
          @apply table-row whitespace-normal border-none;

          &:hover {
            @apply bg-background-surface-subdued;
          }

          & > .table-component__cell {
            @apply border-b border-gray-300;
          }

          &:first-child {
            @apply font-normal;
          }

          &:last-child {
            & > .table-component__cell {
              @apply border-b-0;
            }
          }

          &.table-component__row__expandable {
            @apply bg-background-surface-subdued rounded-none mt-0 w-full shadow-none;

            &:hover {
              @apply bg-background-surface-subdued;
            }
          }

          .table-component__cell {
            @apply table-cell shadow-none rounded-none;

            &:first-child:not(.related-row) {
              @apply pl-6;
            }

            &:last-child {
              @apply pr-6;
            }

            &[data-not-mobile='true'] {
              @apply table-cell;
            }

            & > .button_to input {
              @apply cursor-pointer font-semibold bg-transparent;
              &:hover,
              &:active {
                @apply text-blue-600;
              }
            }

            & > button {
              @apply cursor-pointer text-blue-600 font-semibold;
            }

            & > a {
              @apply cursor-pointer text-blue-600 font-medium !important;
            }

            & > .button_to input {
              @apply cursor-pointer font-semibold bg-transparent;
              &:hover,
              &:active {
                @apply text-blue-600;
              }
            }

            & > button {
              @apply cursor-pointer text-blue-600 font-semibold;
              &:hover,
              &:active {
                text-decoration: solid !important;
                @apply text-interactive-hover !important;
              }
            }

            &:before {
              content: none;
            }

            &.table-component__cell--fix {
              &-left {
                position: sticky !important;
                @apply z-10 bg-background-surface;

                &-last:after {
                  @apply absolute top-0 right-0 w-7 duration-300 transition-shadow;
                  bottom: -1px;
                  content: '';
                  pointer-events: none;
                  transform: translateX(100%);
                }
              }
            }
          }
        }
      }

      &__pagination {
        @apply shadow-none rounded-none;
      }
    }
  }
}

@define-mixin table-mobile {
  .table-component__container {
    .table-component__scroll {
      table {
        @apply w-full flex flex-row flex-nowrap bg-opacity-0;
      }

      .table-component__header__cell,
      .table-component__cell {
        @apply py-2 px-3 relative border-b border-gray-300;
        overflow-wrap: break-word;

        &:first-child:not(.related-row) {
          @apply pl-3;
        }

        &:last-child {
          @apply pr-3;
        }
      }

      .table-component__header {
        @apply hidden;

        .table-component__row {
          @apply flex;
          @apply flex-col;
          @apply flex-nowrap;
          @apply normal-case;

          .table-component__header__cell {
            @apply border-b;
            @apply border-gray-300;

            &:first-child:not(.related-row) {
              @apply pl-6;
            }

            &:last-child {
              @apply pr-6;
            }
          }
        }
      }

      .table-component__body {
        @apply flex-1 bg-background-surface;

        .table-component__row {
          @apply flex flex-col flex-nowrap bg-background-surface rounded mb-2 border border-gray-300;

          &[data-hidden='true'] {
            display: none !important;
          }

          &[data-selected='true'] {
            @apply bg-interactive-bg !important;
          }

          &[data-warning='true'] {
            @apply bg-warning-bg !important;
          }

          &[data-error='true'] {
            @apply bg-danger-bg !important;

            &:focus {
              --bg-opacity: 1;
              background-color: #fdf1f1;
              background-color: rgba(253, 241, 241, var(--bg-opacity));
            }
          }

          &.table-component__row--inline {
            @apply shadow-none rounded-none mb-0 bg-background-surface;

            .table-component__cell:last-child {
              @apply border-gray-300;
            }
          }

          &.table-component__row--empty {
            @apply mb-0 py-3;
          }

          &.table-component__row__expandable {
            @apply border-t border-gray-300 bg-background-surface-subdued rounded-t-none -mt-2;

            &:hover {
              @apply bg-background-surface-subdued;
            }

            .table-component__cell {
              &:before {
                display: none;
              }

              &:first-child:not(.related-row) {
                @apply border-none bg-background-surface-subdued;
              }
            }
          }

          &:last-child {
            .table-component__cell:last-child {
              @apply border-b-0;
            }

            & > .table-component__cell {
              @apply border-b border-gray-300;
            }
          }

          .table-component__cell {
            @apply flex;
            @apply justify-between;
            @apply w-auto;

            & > a {
              @apply cursor-pointer text-blue-700 font-medium !important;
              &:hover,
              &:active {
                text-decoration: solid !important;
                @apply text-interactive-active !important;
              }
            }

            &[data-not-mobile='true'] {
              @apply hidden;
            }

            &:first-child:not(.related-row) {
              @apply pl-3;
            }

            &:last-child {
              @apply pr-3;
            }

            &:before {
              content: attr(data-label);
              @apply text-left;
              @apply mr-2;
              @apply font-semibold;
            }
          }
        }
      }

      &__pagination {
        @apply bg-background-surface;
        @apply rounded;
      }
    }
  }
}

@define-mixin table-scrollable {
  @mixin table;

  &.table-component__ping--right {
    .table-component__container {
      &:after {
        -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
      }
    }
  }

  &.table-component__ping--left {
    .table-component__cell--fix-left-last::after {
      box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
    }
  }
}

/* CLASSES DEF */
.table-component {
  &.table-component--mobile {
    @mixin table-mobile;
  }

  @mixin table-scrollable;
}

@screen sm {
  .table-component {
    &.table-component--mobile {
      @mixin table;
    }

    @mixin table-scrollable;
  }
}
