.switch {
  &:checked {
    & + .switch__slider {
      @apply bg-primary;
    }

    & + .switch__slider:before {
      transform: translateX(100%);
      @apply border-primary;
    }
  }

  &:disabled + .switch__slider {
    @apply opacity-50;
    @apply cursor-not-allowed;
  }
}

.switch__container {
  @apply transition-all;
  @apply duration-300;

  &:focus-within {
    @apply shadow-outline;
  }
}

.switch__slider:before {
  content: '';
  transform: translateX(0px);
  @apply absolute;
  @apply bg-background-surface;
  @apply border-2;
  @apply border-gray-500;
  @apply left-0;
  @apply mb-2;
  @apply rounded-full;
  @apply transition;
  @apply duration-200;
}

.switch__slider--md {
  &:before {
    @apply w-6;
    @apply h-6;
  }

  .switch:not(:disabled) + &:active:before {
    @apply pr-6;
  }

  .switch:not(:disabled):checked + &:active:before {
    @apply pr-6;
    @apply -ml-2;
  }
}

.switch__slider--sm {
  &:before {
    @apply w-4;
    @apply h-4;
  }

  .switch + &:active:before {
    @apply pr-4;
  }

  .switch:checked + &:active:before {
    @apply pr-4;
    @apply -ml-2;
  }
}
