.light-grey-to-transparent-overlay:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
     rgba(245,245,245, 1) 2.5%,
     rgba(245,245,245, 0) 80%
  );
  pointer-events: none;
}
