.overflow-x-shadow {
  background: linear-gradient(90deg, theme('colors.background.surface') 0%, rgba(255, 255, 255, 0)),
    linear-gradient(-90deg, theme('colors.background.surface') 0%, rgba(255, 255, 255, 0)) 100% 0,
    radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 100% 0%;
  background-repeat: no-repeat;
  background-color: theme('colors.background.surface');
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.overflow-x-no-bar {
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.overflow-x-no-bar::-webkit-scrollbar {
  display: none;
}

.no-gutter {
  @apply -mx-6;
  width: auto;
}

.direction-rtl {
  direction: rtl;
}

.disabled {
  @apply opacity-50;
  @apply cursor-not-allowed;
}

.link {
  @apply text-blue-600;

  &:hover {
    @apply text-blue-500;
  }
  &:active,
  &:focus {
    @apply text-blue-400;
  }
}

.grid-multiple-columns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.grid-cart-item {
  display: grid;
  grid-template-columns: 1fr auto;
}

.input-reset {
  @apply border-none p-0;

  &:focus {
    @apply shadow-none ring-0 border-none;
  }
}

.form-input {
  @apply border p-2 rounded transition-all duration-200;

  &:focus-within {
    @apply border-interactive ring-interactive ring-1;
  }
}

.form-input--no-space {
  @apply border rounded transition-all duration-200;

  &:focus-within {
    @apply border-interactive ring-interactive ring-1;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.responsive-contact-item {
	grid-template-columns: repeat(auto-fill, minmax(23ch, 1fr));
}
