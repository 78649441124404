details > summary {
  list-style: none;
}

details[open] .control {
  transform: rotate(180deg);
}


details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}
