.menu_component {
  .menu__item {
    & > a {
      @apply -mx-4;
      @apply -my-2;
      @apply px-4;
      @apply py-2;
      @apply w-full;
      @apply flex-1;
      @apply h-full;
      cursor: inherit;
      pointer-events: inherit;
    }

    & > .button_to input {
      @apply cursor-pointer font-semibold bg-transparent;
      &:hover,
      &:active {
        @apply text-interactive;
      }
    }

    & > a:hover {
      @apply text-dark;
      cursor: inherit;
      pointer-events: inherit;
    }

    &[data-active='true'] {
      @apply bg-secondary-hover;

      &:hover {
        @apply bg-secondary-hover;
      }

      & > a:hover {
        @apply text-default;
      }
    }

    &--active {
      @apply bg-secondary-active;
    }
  }

  .menu__item_orange {
    & > a {
      @apply -mx-4;
      @apply -my-2;
      @apply px-4;
      @apply py-2;
      @apply w-full;
      @apply flex-1;
      @apply h-full;
      cursor: inherit;
      pointer-events: inherit;
    }

    & > .button_to input {
      @apply cursor-pointer font-semibold bg-transparent;
      &:hover,
      &:active {
        @apply text-interactive;
      }
    }

    & > a:hover {
      @apply text-dark;
      cursor: pointer;
      pointer-events: pointer;
    }

    &[data-active='true'] {
      @apply bg-orange-50 font-bold;

      &:hover {
        @apply bg-orange-50;
      }

      & > a:hover {
        @apply text-default;
      }
    }

    &--active {
      @apply bg-white;
    }
  }
}

.global_actions__menu {
  .menu__item {
    & > a {
      @apply -mx-4;
      @apply -my-2;
      @apply px-4;
      @apply py-2;
      @apply w-full;
      @apply flex-1;
      @apply h-full;
      cursor: inherit;
      pointer-events: inherit;
    }

    & > a:hover {
      @apply text-inverted bg-gradient-to-r from-orange-300 to-orange-400;
      cursor: inherit;
      pointer-events: inherit;
    }

    &[data-active='true'] {
      &:hover {
        @apply bg-gradient-to-r from-orange-300 to-orange-400;
      }

      & > a:hover {
        @apply text-inverted;
      }
    }

    &--active {
      @apply bg-gradient-to-r from-orange-300 to-orange-400;
    }
  }
}
