.timeline {
  & > .item > .icon {
    @apply absolute grid place-items-center;
    margin-top: theme('spacing.1');
    height: theme('spacing.4');
    width: theme('spacing.4');
    box-shadow: 0 0 0 4px theme('colors.background.base');
  }

  & > .item:not(:last-child) > .line {
    @apply absolute w-px bg-gray-300;
    left: calc(theme('spacing.4') / 2);
    top: calc(theme('spacing.4') + theme('spacing.2'));
    height: calc(100% - theme('spacing.4'));
  }
}
