:is(.editable-component[data-state='preview'], .editable-component[data-state='edit']) .spinner {
  display: none;
}

:is(.editable-component[data-state='submitting'], .editable-component[data-state='edit'])
  .indicator {
  display: none;
}

.editable-component[data-state='submitting'] .spinner {
  display: inline;
}

.editable-component[data-state='preview'] .indicator {
  display: inline;
}
