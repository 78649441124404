.dropzone--default {
  @apply border-2;
  @apply rounded;
  @apply border-gray-300;
  @apply border-dashed;
  @apply text-lg;
  @apply text-light;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply font-semibold;

  &.dz-drag-hover {
    @apply border-2;
    @apply border-gray-400;
    @apply border-dashed;
  }

  &.dz-preview {
    &.dz-error-message {
      background: #dd3535 !important;
      @apply rounded;

      &::after {
        @apply border-red-500;
      }
    }
    &.dz-image {
      @apply rounded;
    }
  }
}
