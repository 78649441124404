.panel-component--with_table {
  @apply shadow-none;

  .panel-component__body {
    @apply px-0;
    @apply py-0;
  }
}

@screen sm {
  .panel-component--with_table {
    .panel-component__body {
      @apply px-0;
      @apply py-0;
    }
  }
}
