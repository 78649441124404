.turbo-progress-bar {
  @apply bg-primary;
}

html {
  margin-right: 0;
}

.layout__container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  &[data-sidebar-visible='false'] {
    .layout__content {
      left: 0px;
    }
  }

  &[data-sidebar-visible='true'] {
    .layout__content {
      left: 260px;
    }
  }

  .layout__content {
    @apply absolute top-0 bottom-0 right-0 flex flex-col;
    min-height: 100vh;
    left: 260px;
  }

  .layout__content_small {
    @apply absolute top-0 bottom-0 right-0 flex flex-col;
    min-height: 100vh;
    left: 115px;
  }

  .layout__content__bar {
    @apply relative;
  }

  .layout__content__header {
  }

  .layout__content__main {
    @apply relative pb-6 overflow-y-scroll;
    height: 100%;
    padding-right: 10px;
  }
}
