/* Libraries */
/* purgecss start ignore */
@import 'choices.js/public/assets/styles/choices.css';
@import './dropzone';
/* purgecss end ignore */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Layouts */
@import './layouts/centered_with_wave_divider.css';

/* View Components, Stimulus */
@import './components/date_picker_component';
@import './components/select_component';
@import './components/switch_component';
@import './components/menu_item_component';
@import './components/upload_component';
@import './components/editable_avatar_component';
@import './components/tooltip_component';
@import './components/button_component';
@import './components/panel_component';
@import './components/nav_tabs_component';
@import './components/dropdown_component';
@import './components//order_line_items_addition_compoment';
@import './components/radio_card_component';
@import './components/action_group_component';
@import './components/sidebar_menu_component';
@import './components/timeline_component';
@import './components/editable_component';
@import './components/steps_component';
@import './components/line_item_field_component.css';
@import './setup_keycloak_alert.css';

/* Base */
@import './reset';
@import './layout';
@import './table';
@import './form_validation';
@import './notifications';
@import './layout';
@import './custom_utilities';
@import './details';

/* 3rd party */
@import './pagy';
@import './spreedly';

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply border border-gray-300 rounded text-base px-2 py-2;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      @apply border-interactive ring-1 ring-interactive;
    }
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-placeholder;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply border border-gray-300 rounded text-orange-400;
  }

  [type='checkbox']:focus,
  [type='radio']:focus {
    outline: none;
    @apply border-primary ring-1 ring-interactive;
  }

  [type='submit'] {
    @apply font-semibold;
  }

  [role=alert] a {
    @apply underline;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.aria-selected\:bg-gray-300[aria-selected="true"] {
  @apply bg-gray-300;
}
