.expandable-btn {
  @apply relative;
  height: 19px;
  width: 19px;

  @apply float-left;
  @apply border;
  @apply border-gray-300;
  @apply rounded-full;
  @apply transition-colors;
  @apply duration-200;

  &:focus {
    @apply outline-none;
  }

  &:focus,
  &:active,
  &:hover {
    @apply border-gray-700;
  }

  &::before {
    content: '';

    @apply absolute;
    @apply w-divider;
    left: 8px;
    bottom: 3px;
    top: 3px;
    @apply bg-gray-700;
    @apply transition-colors;
    @apply duration-200;
  }

  &::after {
    content: '';
    @apply absolute;
    @apply h-divider;
    top: 8px;
    right: 3px;
    left: 3px;
    @apply bg-gray-700;
    @apply transition-colors;
    @apply duration-200;
  }

  &[data-expanded='true'] {
    &::before {
      animation: expandAnimationBefore 0.25s ease-out forwards;
    }

    &::after {
      animation: expandAnimationAfter 0.25s ease-out forwards;
    }
  }

  &[data-expanded='false'] {
    &::before {
      animation: collapseAnimationBefore 0.25s ease-out none;
    }

    &::after {
      animation: collapseAnimationAfter 0.25s ease-out none;
    }
  }
}

@keyframes expandAnimationBefore {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes expandAnimationAfter {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes collapseAnimationAfter {
  0% {
    transform: rotate(-90deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}

@keyframes collapseAnimationBefore {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
