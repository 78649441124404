tooltip_component_arrow,
.tooltip_component_arrow::before {
  width: 8px;
  height: 8px;
  z-index: -1;
  @apply absolute;
}

.tooltip_component_arrow::before {
  content: '';
  transform: rotate(45deg);
  @apply bg-gray-900;
}

.tooltip_component__popper[data-popper-placement^='top'] .tooltip_component_arrow {
  bottom: 4px;
}

.tooltip_component__popper[data-popper-placement^='bottom'] .tooltip_component_arrow {
  top: -4px;
}

.tooltip_component__popper[data-popper-placement^='left'] .tooltip_component_arrow {
  right: -4px;
}

.tooltip_component__popper[data-popper-placement^='right'] .tooltip_component_arrow {
  left: -4px;
}
