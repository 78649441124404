.editable_avatar__avatar--size {
  width: calc(8rem - 16px);
  height: calc(8rem - 16px);
}

.editable_avatar__preview_image {
  border-radius: 9999px !important;
  width: calc(8rem - 16px) !important;
  height: calc(8rem - 16px) !important;
}
