@import 'flatpickr/dist/flatpickr.css';

.flatpickr-input {
  @apply bg-transparent;
}

.flatpickr-input:focus {
  @apply outline-none;
}

.flatpickr-current-month {
  @apply text-lg;
}

.flatpickr-calendar {
  @apply bg-white;
  @apply shadow-lg;
  @apply rounded;
}

.flatpickr-day {
  @apply rounded;

  &.inRange {
    box-shadow: -5px 0 0 #fed4bb, 5px 0 0 #fed4bb;
  }

  &.today {
    &:hover,
    &:focus {
      @apply bg-orange-500;
      @apply border-orange-500;
    }

    @apply border-orange-500;
  }
}

.flatpickr-day.inRange:hover,
.flatpickr-day.inRange:focus {
  @apply border-dashed;
  @apply border-orange-500;
  @apply bg-orange-200;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange {
  @apply bg-orange-200;
  @apply border-orange-200;
}

.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply bg-gray-100;
  @apply border-gray-100;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  @apply rounded-tl;
  @apply rounded-bl;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply bg-orange-500;
  @apply text-orange-100;
  @apply border-orange-500;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #fed4bb;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  @apply rounded-tr;
  @apply rounded-br;
}

.flatpickr-months {
  .flatpickr-month {
    height: 46px;
  }

  .flatpickr-current-month {
    height: 46px;
    @apply inline-flex;
    @apply items-center;
  }

  .numInputWrapper {
    width: 100%;
    .arrowUp,
    .arrowDown {
      display: none;
    }
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: 8px;
  }
}
