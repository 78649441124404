.pagy-nav,
.pagy-nav-js,
.pagy-combo-nav-js {
  @apply inline-flex;
}
.pagy-nav.pagination,
.pagy-nav-js.pagination,
.pagy-combo-nav-js.pagination {
  @apply rounded bg-white pointer-events-auto;
}
.pagy-nav .page,
.pagy-nav-js .page,
.pagy-combo-nav-js .page,
.pagy-combo-nav-js .pagy-combo-input {
  @apply text-gray-700 flex items-center border text-sm leading-tight font-medium;

  & > a {
    @apply px-3 py-2;
  }
}

.pagy-nav .page:hover,
.pagy-nav-js .page:hover {
  @apply text-gray-900;
}
.pagy-nav .disabled,
.pagy-nav-js .disabled,
.pagy-combo-nav-js .disabled {
  @apply cursor-not-allowed px-3 py-2;
}
.pagy-nav .active,
.pagy-nav-js .active {
  @apply text-orange-500 bg-orange-100 border border-orange-200 px-3 py-2;
}
.pagy-nav .prev,
.pagy-nav-js .prev,
.pagy-combo-nav-js .prev {
  @apply text-gray-900 border-r-0 border-l border-t border-b rounded-l;
}
.pagy-nav .next,
.pagy-nav-js .next,
.pagy-combo-nav-js .next {
  @apply text-gray-900 border-l-0 border-r border-t border-b rounded-r;
}

.page-nav .gap,
.pagy-nav-js .gap,
.pagy-combo-nav-js .gap {
  @apply px-3 py-2;
}

span.page.gap {
  @apply px-3;
  @apply py-2;
}

@media screen and (max-width: 640px) {
  .pagy-nav .page,
  .pagy-nav-js .page,
  .pagy-combo-nav-js .page,
  .pagy-combo-nav-js .pagy-combo-input {
    @apply hidden;
  }

  .pagy-nav .prev,
  .pagy-nav-js .prev,
  .pagy-combo-nav-js .prev {
    @apply flex items-center;

    & > a {
      @apply px-3 py-2;
    }
  }
  .pagy-nav .next,
  .pagy-nav-js .next,
  .pagy-combo-nav-js .next {
    @apply flex border items-center;

    & > a {
      @apply px-3 py-2;
    }
  }
}
