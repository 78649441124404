@import 'dropzone/dist/min/dropzone.min.css';

.dropzone .dz-preview {
  @apply m-2;
}

.dropzone .dz-preview:hover {
  @apply z-40;
}

.dropzone .dz-preview.dz-image-preview {
  background: none;
}

.dropzone .dz-preview .dz-error-message {
  background: #dd3535 !important;
  @apply text-white;
  top: 146px !important;
}

.dropzone .dz-preview .dz-error-message::after {
  @apply border-red-500;
  border-left: solid 4px transparent;
  border-right: solid 4px transparent;
  @apply border-b-4;
  top: -4px;
}

.dropzone.dz-clickable * {
  cursor: unset;
}

.dropzone--avatar {
  @apply border;
  @apply rounded-full;
  @apply min-h-0;
  @apply w-32;
  @apply h-32;
  @apply border-gray-300;
  @apply text-lg;
  @apply text-light;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply font-semibold;

  &.dz-image {
    @apply rounded-full;
    @apply w-full;
    @apply h-full;
  }

  &.dz-drag-hover {
    @apply border;
    @apply border-gray-400;
  }
}

.dropzone--event-input {
  @apply border-gray-200 !important;
  &.dz-button {
    @apply text-light !important;
  }
}
