.keycloak_alert_layout {
  @media screen and (max-width: 1024px) {
    @apply p-0 items-center border-none shadow-none;
  }
}

.keycloak_alert_main_content {
  justify-content: center;

  @media screen and (max-width: 1024px) {
    @apply items-center grid-cols-none;
  }
}

.order_large_logo {
  @media screen and (max-width: 1024px) {
    padding-left: 0px;
    margin-bottom: 10px;
    margin-top: 0px;
    padding-top: 0px;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
}

.ollie_logo {
  height: 50px;
  width: 130px;

  @media screen and (max-width: 640px) {
    display: none;
  }
}

.keycloak_alert_heading {
  margin-left: -150px;

  @media screen and (max-width: 1024px) {
    margin-left: 0px;
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    margin-top: 0px;
    font-size: medium;
  }
}

.keycloak_alert_buttons {
  @media screen and (max-width: 1024px) {
    @apply justify-center text-center;
  }
}

.person_with_tablet {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.terms_and_conditions_large {
  @media screen and (max-width: 1024px) {
    visibility: hidden;
  }
}

.terms_and_conditions_small {
  @media screen and (min-width: 1024px) {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    @apply text-center;
  }
}

.terms_and_conditions_small_no_email {
  @media screen and (min-width: 1024px) {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    @apply pt-6 space-x-10;
  }

  @media screen and (max-width: 640px) {
    @apply text-center space-x-2;
  }
}

#desktop-menu {
  @media screen and (max-width: 640px) {
    @apply hidden;
  }
}
