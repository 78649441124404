.choices {
  @apply transition-shadow;
  @apply ease-in-out;
  @apply duration-200;
  @apply mb-0;

  &.is-open,
  &.is-focus {
    @apply rounded;

    .choices__inner {
      @apply rounded;
    }
  }
}

.choices__list--single {
  @apply p-0;
  line-height: 1.5rem;
}

.choices__inner {
  @apply min-h-0 px-4 py-2 text-base;

  input[type='text'] {
    padding: 0px !important;
    @apply m-0;
  }
}

.choices__placeholder {
  @apply text-placeholder;
}

.choices__input {
  @apply bg-background-surface rounded-none border-none p-0 m-0;

  &:focus {
    @apply ring-0;
  }
}

.choices__list--multiple .choices__item {
  @apply bg-gray-700;
  @apply border-0;
  @apply rounded;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  &.choices__item--selectable.is-highlighted {
    @apply bg-secondary-hover;
    @apply text-dark;
  }
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  @apply border-gray-300;
}

.choices__list--dropdown {
  &.is-active {
    @apply border-gray-300;
    @apply border;
  }

  @apply shadow-fluffy-1;
  @apply rounded;
  @apply border;
  @apply border-gray-300;
  @apply mt-1;
}
.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
  @apply border-l;
  @apply border-gray-300;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    @apply pr-2;
  }
}
