@import 'izitoast/dist/css/iziToast.min.css';

.iziToast-title {
  @apply capitalize;
}

.iziToast > .iziToast-body .iziToast-title {
  @apply text-dark mb-1 text-lg;
}

.iziToast > .iziToast-body .iziToast-message {
  margin: 0 0 10px;
  @apply text-default;
}

.iziToast.iziToast-animateInside .iziToast-icon {
  opacity: 1 !important;
}

.iziToast > .iziToast-body .iziToast-buttons > a,
.iziToast > .iziToast-body .iziToast-buttons > button,
.iziToast > .iziToast-body .iziToast-buttons > input:not([type='checkbox']):not([type='radio']) {
  @apply text-default rounded font-medium;
}

.iziToast.iziToast-color-red {
  @apply bg-background-surface border-t-4 border-red-400;
}
.iziToast.iziToast-color-orange {
  @apply bg-background-surface border-t-4 border-yellow-400;
}
.iziToast.iziToast-color-blue {
  @apply bg-background-surface border-t-4 border-blue-400;
}
.iziToast.iziToast-color-green {
  @apply bg-background-surface border-t-4 border-green-400;
}
