.steps-component {
  --size-dot: 16px;

  & > .step > .dot {
    height: var(--size-dot);
    width: var(--size-dot);
  }

  & > .step {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      top: calc(var(--size-dot) / 2);
      transform: translateX(-50%);
      @apply absolute bg-gray-300;
    }

    &[data-highlight='true']:after {
      @apply bg-primary;
    }

    &:first-child:after {
      content: none;
    }
  }
}

@media (min-width: 1024px) {
  .steps-component {
    --size-dot: 20px;
  }
}
