.line_item_field_component {
  & > .content {
    @apply gap-2;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'product-name price'
      '. quantity';

    &[data-inventory-visible='true'] {
      grid-template-columns: 1fr auto;
      grid-template-areas:
        'product-name price'
        'inventory quantity';
    }

    & > .product-name {
      grid-area: product-name;
    }

    & > .inventory {
      grid-area: inventory;
    }

    & > .quantity {
      grid-area: quantity;
    }

    & > .price {
      grid-area: price;
    }
  }
}

@screen md {
  .line_item_field_component {
    & > .content {
      @apply gap-x-2;
      display: grid;
      grid-template-columns: 1fr auto minmax(9ch, auto);
      grid-template-areas: 'product-name quantity price';

      &[data-inventory-visible='true'] {
        grid-template-columns: 2fr 1fr auto minmax(9ch, auto);
        grid-template-areas: 'product-name inventory quantity price';
      }
    }
  }
}
